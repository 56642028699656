// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
// components
import { MotionContainer } from '../components/animate';
import Page from '../components/Page';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
  '.MuiTypography-body1': {
    color: '#000'
  },
  b: {
    color: '#000'
  }
}));

const BreadCrumb = styled('div')(({ theme }) => ({
  color: '#000',
  fontSize: '12px',
  ul: {
    display: 'flex',
    listStyle: 'none',
    gap: '7px',
    whiteSpace: 'nowrap'
  }
}));

// ----------------------------------------------------------------------
const HowItWorks = () => {
  return (
    <RootStyle title="How It Works">
      <Container>
        <BreadCrumb>
          <ul>
            <li>
              <Link to={'/'}>TradingTown</Link>
            </li>
            <li> {'>'} How TradingTown Space works?</li>
          </ul>
        </BreadCrumb>
        <MotionContainer initial="initial" open>
          <Box sx={{ margin: 'auto', paddingTop: '20px' }}>
            <Typography variant="h2" paragraph sx={{ color: '#4680F0', fontWeight: 500 }}>
              How TradingTown Space Works
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              TradingTown Space is a unique platform designed to help you assess the historical
              performance of buy transactions made by insiders. We provide detailed insights into
              which insiders' buy transactions tend to generate positive returns over various time
              frames.
            </Typography>
            <br />
            <Typography variant="h5" paragraph sx={{ color: '#4680F0' }}>
              Data
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The data used by TradingTown Space is directly sourced from SEC Form 4 filings.
              Insiders, including officers, C-Suites, directors, and 10% owners, are required to
              disclose their trades by filing a Form 4.{' '}
              <span style={{ textDecoration: 'underline' }}>
                Form 4 must be filed within two business days following the transaction date.
              </span>
            </Typography>
            <br />
            <Typography variant="h5" paragraph sx={{ color: '#4680F0' }}>
              Ranking System
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The platform ranks each insider transaction based on several factors:
              <ul style={{ marginLeft: '30px' }}>
                <li>
                  <b>Percentage change</b> in ownership caused by the buy transaction
                </li>
                <li>
                  <b>Win rates</b> of the insider’s previous buy transactions
                </li>
                <li>
                  <b>Number of historical trades</b> made by the insider
                </li>
                <li>
                  <b>Dollar amount</b> of the trade
                </li>
                <li>
                  <b>Number of unique insiders</b> who have purchased the same stock in the past 12
                  months
                </li>
              </ul>
              <br />
              <Typography variant="h5" paragraph sx={{ color: '#4680F0' }}>
                Timeframe
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Win rates are based on one year stock return after the insider's buy transaction.
              </Typography>
              <br />
              Every insider buy transaction is assigned a score between 1 and 10. A lower score
              indicates a better trade.
              <br />
              <br /> The algorithm that powers TradingTown Space is based on an analysis of over
              100,000 insider trades made over the last 8 years. This deep data analysis helps
              create accurate and actionable transaction scores for each insider buy.
            </Typography>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default HowItWorks;
