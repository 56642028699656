import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import analyticsReducer from './slices/analytics';
import stockfundamentals from './slices/stockfundamentals';
import stockinsidertrading from './slices/stockinsidertrading';
import tickerReducer from './slices/ticker';
import stockReducer from './slices/stock';
import watchReducer from './slices/watch';
import stockSearchReducer from './slices/stockSearch';
import stocktechnicalanalysis from './slices/stocktechnicalanalysis';
import optionsactivity from './slices/optionsactivity';
import themeReducer from './slices/theme';
import insidersReducer from './slices/insiders';
import emailPreferencesReducer from './slices/email-preferences';
import subscriptionReducer from './slices/subscription';
import topInsiderBuysReducer from './slices/top-insider-buys';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  analytics: analyticsReducer,
  ticker: tickerReducer,
  stock: stockReducer,
  stockSearch: stockSearchReducer,
  watch: watchReducer,
  stockfundamentals: stockfundamentals,
  stockinsidertrading: stockinsidertrading,
  stocktechnicalanalysis: stocktechnicalanalysis,
  optionsActivitiesArray: optionsactivity,
  theme: themeReducer,
  insiders: insidersReducer,
  emailPreferences: emailPreferencesReducer,
  subscription: subscriptionReducer,
  topInsiderBuys: topInsiderBuysReducer
});

export { rootPersistConfig, rootReducer };
