import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type SubscriptionState = {
  isLoading: boolean;
  error: boolean;
  subscriptions: any[];
  subscription: any;
  paymentHistory: any;
};

const initialState: SubscriptionState = {
  isLoading: false,
  error: false,
  subscriptions: [],
  subscription: {},
  paymentHistory: []
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSuccessSubscription(state, action) {
      state.isLoading = false;
      state.subscription = action.payload;
    },

    getSubscriptions(state, action) {
      state.isLoading = false;
      state.subscriptions = action.payload;
    },

    getSubscriptionDetail(state, action) {
      state.isLoading = false;
      state.subscription = action.payload;
    },

    getSubscriptionPaymentHistory(state, action) {
      state.isLoading = false;
      state.paymentHistory = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function createCheckoutSession(body: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/subscriptions/create-checkout-session`, body);
      window.location.href = response?.data?.url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createCustomerPortalSession(body: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/v1/subscriptions/create-customer-portal-session`,
        body
      );
      window.location.href = response?.data?.url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getSubscription() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/subscriptions/details`);
      dispatch(slice.actions.getSubscriptionDetail(response?.data?.subscription || response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
