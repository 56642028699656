import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from '../../redux/store';
import { getPreferencesList } from '../../redux/slices/email-preferences';
import { ENABLE_TOKENIZED_PATH } from '../../config/email-preferences';
import { savePreferences, resetPreferenceSuccess } from '../../redux/slices/user';
import { RootState, useSelector } from '../../redux/store';
import { setShowLoginModalWithoutClose } from '../../redux/slices/user';
import closeFill from '@iconify/icons-eva/close-fill';
import useAuth from 'hooks/useAuth';
import {
  Box,
  Container,
  Grid,
  Card,
  CircularProgress,
  Button,
  Backdrop,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import Page from '../../components/Page';
import { useSnackbar } from 'notistack';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from 'routes/paths';
import { getSubscription, createCustomerPortalSession } from 'redux/slices/subscription';
import moment from 'moment';

const RootStyle = styled(Page)(({ theme }) => ({
  marginTop: '100px',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '500px',
  margin: '50px auto',
  textAlign: 'center',
  '.preference-container': {
    marginTop: '40px'
  },
  ul: {
    textAlign: 'left',
    listStyle: 'none'
  },
  '.update': {
    width: '385px',
    height: '57px',
    color: '#fff',
    backgroundColor: '#1565D8',
    borderRadius: '30px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '25px',
    boxShadow: 'none',
    '@media (max-width: 385px)': {
      width: '100%'
    }
  },
  '.unsubscribe': {
    width: '385px',
    height: '57px',
    color: '#1565D8',
    border: '1px solid #1565D8',
    backgroundColor: '#fff',
    borderRadius: '30px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '30px',
    boxShadow: 'none',
    '&:hover': {
      color: '#fff'
    },
    '@media (max-width: 385px)': {
      width: '100%'
    }
  },
  hr: {
    border: '1px solid #9C9A9A',
    margin: '50px auto'
  }
}));

export default function MyAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, logout } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { subscription } = useSelector((state: RootState) => state.subscription);

  useEffect(() => {
    dispatch(getSubscription());
  }, []);

  const handleCustomerPortal = (subscriptionId: any) => {
    dispatch(createCustomerPortalSession({ subscriptionId }));
  };

  const handleLogout = () => {
    logout();
    navigate('/insidertrading');
  };

  return (
    <RootStyle title="My Account | Trading Hub">
      <Container maxWidth="sm" sx={{ p: 5 }}>
        <Grid>
          <Card sx={{ p: 5 }}>
            <Box>
              <Typography fontWeight={'bold'}>Account Details</Typography>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                  <Typography>User Name:</Typography>
                  <Typography>Email:</Typography>
                </Box>
                <Box>
                  <Typography>{user?.name}</Typography>
                  <Typography>{user?.email}</Typography>
                </Box>
              </Box>
            </Box>

            {subscription && (
              <>
                <Box mt={5}>
                  <Typography fontWeight={'bold'}>Manage Subscription</Typography>
                </Box>
                <Box>
                  <Box mt={1}>
                    <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                      <Box>
                        <Typography>Pricing Plan: {subscription?.productName}</Typography>
                        {subscription?.showGoodThrough && (
                          <Typography>
                            Expiring, good through:{' '}
                            {moment(subscription?.expires_at)?.utc()?.format('MMMM DD, YYYY')}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography>{subscription?.status}</Typography>
                      </Box>
                    </Box>

                    <Button
                      variant="outlined"
                      sx={{ marginBottom: 2 }}
                      onClick={() => handleCustomerPortal(subscription?.subscriptionId)}
                    >
                      Click here to manage Subscription
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            <Box mt={5}>
              <Typography fontWeight={'bold'}>Sign Out</Typography>
              <Button variant="outlined" sx={{ mt: 2 }} onClick={handleLogout}>
                Sign Out of Account
              </Button>
            </Box>
          </Card>
        </Grid>

        <Box mb={15}></Box>
      </Container>
    </RootStyle>
  );
}
