import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../Label';
import { RootState, useDispatch, useSelector } from 'redux/store';
import useAuth from 'hooks/useAuth';
import { setShowLoginModal } from 'redux/slices/user';
import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(3),
  '.btn-cnt': {
    flex: 1,
    alignItems: 'end',
    display: 'flex'
  },
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

type PricingPlanCardProps = {
  card: {
    subscriptionName: string;
    price: number;
    caption: string;
    icon: JSX.Element;
    labelAction: string;
    lists: {
      text: string;
      isAvailable: boolean;
      highlight?: string | null;
    }[];
    priceId_Monthly?: string | null;
    priceId_Annually?: string | null;
  };
  index: number;
  isYearly?: boolean;
  handlePackageSelection: any;
  planChange?: any;
};

export default function PricingPlanCard({
  card,
  index,
  isYearly,
  handlePackageSelection
}: PricingPlanCardProps) {
  const {
    subscriptionName,
    icon,
    price,
    caption,
    lists,
    labelAction,
    priceId_Monthly,
    priceId_Annually
  } = card;

  const dispatch = useDispatch();
  const { user } = useAuth();
  const [priceId, setPriceId] = useState(priceId_Monthly);
  const { subscription } = useSelector((state: RootState) => state.subscription);

  const handleSelection = () => {
    // if (user) {
    handlePackageSelection(priceId);
    // } else {
    //   dispatch(setShowLoginModal());
    // }
  };

  useEffect(() => {
    if (isYearly) {
      setPriceId(priceId_Annually);
    } else {
      setPriceId(priceId_Monthly);
    }
  }, [isYearly]);

  let [currentSubscription, setCurrentSubscription] = useState('');
  useEffect(() => {
    if (subscription?.priceId == priceId && subscription?.status == 'active') {
      setCurrentSubscription(subscription);
    }
  }, [subscription]);

  let [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    if (subscription?.expires_at) {
      if (moment().diff(subscription?.expires_at, 'seconds') < 0) {
        setIsExpired(true);
      }
    }
  }, [subscription]);

  return (
    <RootStyle>
      {/* @ts-ignore */}
      {/* "Active" when subscription is either active, or canceled but not yet expired */}
      {subscription?.priceId == priceId &&
        /* @ts-ignore */
        (subscription?.status == 'active' ||
          /* @ts-ignore */
          (subscription?.status == 'canceled' &&
            moment().diff(subscription?.expires_at, 'seconds') < 0)) && (
          <Label
            color="info"
            sx={{
              top: 16,
              right: 16,
              position: 'absolute'
            }}
          >
            Active
          </Label>
        )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscriptionName}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        {/* "$" dollar sign for 2nd and 3rd card (non-free cards) */}
        {index === 1 || index === 2 ? (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            $
          </Typography>
        ) : (
          ''
        )}

        {/* Price dollar amount */}
        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0
            ? 'Free'
            : !isYearly
            ? price
            : (price * 12 - price * 12 * (20 / 100))?.toFixed(2)}
        </Typography>

        {/* "/mo" or "/yr" string for cards 2 and 3 (non-free cards) */}
        {index === 1 || index === 2 ? (
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            {isYearly ? '/yr' : '/mo'}
          </Typography>
        ) : (
          ''
        )}
      </Box>

      {/* "save 20% a year" */}
      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize'
        }}
      >
        {price === 0 ? caption : isYearly ? caption : ''}
      </Typography>

      <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>

      {/* list of features for each tier */}
      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        {lists.map((item, index) => {
          const { text, highlight } = item;

          // Split text into parts if highlight exists
          const parts = highlight ? text.split(highlight) : [text];

          return (
            <Stack
              key={text}
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{
                typography: 'body2',
                color: item.isAvailable ? 'text.primary' : 'text.disabled'
              }}
            >
              <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
              <Typography variant="body2">
                {parts[0]}
                {highlight && (
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: 'primary.main', fontWeight: 'bold' }}
                  >
                    {highlight}
                  </Typography>
                )}
                {parts[1]}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
      <div className="btn-cnt">
        <Button
          // to={PATH_DASHBOARD.root}
          fullWidth
          size="large"
          variant="contained"
          disabled={index === 0 || isExpired}
          // component={RouterLink}
          onClick={handleSelection}
        >
          {subscription?.priceId == priceId &&
          (subscription?.status == 'active' ||
            /* @ts-ignore */
            subscription?.showGoodThrough)
            ? 'Current Plan'
            : labelAction}
        </Button>
      </div>
    </RootStyle>
  );
}
