import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { PATH_AUTH } from 'routes/paths';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { carryForrwardQueryParams } from 'utils/carryForwardQueryParams';
import { UTMParams } from '../../contants/questions';
import CloseIcon from '@mui/icons-material/Close';
import { trackEvent } from '../../components/amplitute';
import Pricing from './Pricing';

const CardContainer = styled(Card)(({ theme }) => ({
  width: '850px',
  margin: 'auto',
  overflow: 'visible',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const CloseContainer = styled('div')(({ theme }) => ({
  textAlign: 'right',
  position: 'relative',
  svg: {
    cursor: 'pointer',
    right: 0,
    color: '#000'
  }
}));

const PricingModal = ({
  showModal,
  redirectURL,
  onClose
}: {
  showModal: boolean;
  redirectURL: string;
  onClose: () => void;
}) => {
  const [open, setShowModal] = useState(true);
  const Location = useLocation();

  useEffect(() => {
    setShowModal(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
    onClose && onClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={true}
      onClose={handleClose}
      BackdropProps={{ style: { background: 'rgba(0,0,0, 0.76)' } }}
      sx={{ top: '10%' }}
    >
      <CardContainer>
        <CloseContainer>
          <CloseIcon onClick={handleClose} />
        </CloseContainer>
        <div
          style={{
            overflow: 'auto',
            maxHeight: '700px',
            maxWidth: '90%',
            textAlign: 'center',
            margin: 'auto',
            padding: '0 0 70px 0'
          }}
        >
          <Pricing
            planChange={false}
            title1="Upgrade NOW for Daily Top Insider Buys"
            title2="You need a silver plan or gold plan to view daily top insider buys, upgrade now to view and get daily email alerts on top insider buys. Pick a plan from below:"
          />
        </div>
      </CardContainer>
    </Modal>
  );
};

export default PricingModal;
