import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from '../../redux/store';
// material
import { styled } from '@mui/material/styles';
import { Container, Button, Backdrop, CircularProgress } from '@mui/material';
// routes
import Page from '../../components/Page';
import { getWeeklyTopBuysList } from '../../redux/slices/top-insider-buys';
import { RootState, useSelector } from '../../redux/store';
import useAuth from 'hooks/useAuth';
import { trackEvent } from '../../components/amplitute';
import { getSubscription } from 'redux/slices/subscription';
import PricingModal from 'pages/pricing/PricingModal';
import CardsSection from './CardsSection';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  marginTop: '120px',
  minHeight: '300px',
  '.hide-filters': {
    opacity: 0,
    display: 'none',
    transition: 'opacity 0.5s ease-out;'
  },
  '.show-filters': {
    opacity: 1,
    display: 'flex',
    transition: 'opacity 0.5s ease-out;',
    '@media (min-width: 900px) and (max-width: 1150px)': {
      alignItems: 'center'
    }
  }
}));

const FilterTopStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '30px',
  '.filterTitle': {
    display: 'flex',
    alignItems: 'center'
  },
  '.filterSwitchBtn': {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between'
  },
  h1: {
    color: '#000',
    fontSize: '55px',
    fontWeight: 700,
    lineHeight: '40px',
    paddingRight: '42px'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '.filterTitle': {
      width: '100%',
      textAlign: 'left'
    },
    h1: {
      fontSize: '30px'
    }
  }
}));

export default function WeeklyTopInsiderBuys() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const {
    weeklyTopBuys = [],
    isLoadingWeekly,
    weeklyError
  } = useSelector((state: RootState) => state.topInsiderBuys);

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(getWeeklyTopBuysList());
  }, []);

  const onClosePricing = () => {
    setShowModal(false);
  };

  const triggerModal = () => {
    setShowModal(true);
  };

  return (
    <RootStyle title="Daily top insider buys | TradingTown Space">
      <Container>
        <ContentStyle>
          <FilterTopStyle>
            <div className="filterTitle">
              <h1>Past Weekly Top Insider Buys</h1>
            </div>
          </FilterTopStyle>
          <CardsSection
            triggerModal={triggerModal}
            type="weekly"
            topBuys={weeklyTopBuys ? weeklyTopBuys.map((tbuys) => tbuys.reportDate) : []}
          ></CardsSection>
          <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
            {/* <Button variant="contained">Load More</Button> */}
          </div>
        </ContentStyle>
        <PricingModal showModal={showModal} redirectURL={''} onClose={onClosePricing} />
      </Container>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'rgba(22,28,36, 0.5)' }}
        open={isLoadingWeekly}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </RootStyle>
  );
}
