import { useEffect } from 'react';
import { useDispatch } from '../../redux/store';
// material
import { styled } from '@mui/material/styles';
import { Container, CircularProgress, Backdrop } from '@mui/material';
import { useParams, Link, useNavigate } from 'react-router-dom';
// routes
import Page from '../../components/Page';
import { getDailyTopBuysList } from '../../redux/slices/top-insider-buys';
import { RootState, useSelector } from '../../redux/store';
import useAuth from 'hooks/useAuth';
import { trackEvent } from '../../components/amplitute';
import { getSubscription } from 'redux/slices/subscription';
import moment from 'moment';
import { isValidDate } from '../../utils/formatTime';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const BreadCrumb = styled('div')(({ theme }) => ({
  color: '#000',
  fontSize: '12px',
  ul: {
    display: 'flex',
    listStyle: 'none',
    gap: '7px',
    whiteSpace: 'nowrap'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  marginTop: '120px',
  minHeight: '300px',
  '.hide-filters': {
    opacity: 0,
    display: 'none',
    transition: 'opacity 0.5s ease-out;'
  },
  '.show-filters': {
    opacity: 1,
    display: 'flex',
    transition: 'opacity 0.5s ease-out;',
    '@media (min-width: 900px) and (max-width: 1150px)': {
      alignItems: 'center'
    }
  },
  '.content-ctn': {
    paddingTop: '20px',
    paddingBottom: '20px',
    overflow: 'auto'
  }
}));

export default function DailyTopBuy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { date = '' } = useParams();
  const { isAuthenticated } = useAuth();
  const { subscription } = useSelector((state: RootState) => state.subscription);
  const {
    dailyTopBuyContent = '',
    isLoadingDaily,
    dailyError
  } = useSelector((state: RootState) => state.topInsiderBuys);

  useEffect(() => {
    if (!isValidDate(date)) {
      navigate('/daily-top-insider-buys');
      return;
    }
    dispatch(getSubscription());
    dispatch(getDailyTopBuysList(date));
  }, []);

  useEffect(() => {
    if (!isAuthenticated && dailyTopBuyContent) {
      navigate('/daily-top-insider-buys');
      return;
    }
    const expiresAt = subscription?.expires_at
      ? moment.utc(subscription.expires_at).local().valueOf()
      : 0;
    if (
      isAuthenticated &&
      subscription &&
      Object.keys(subscription).length &&
      subscription?.status !== 'active'
    ) {
      if (moment().toDate().getTime() > expiresAt) {
        navigate('/daily-top-insider-buys');
        return;
      }
    }
  }, [subscription, isAuthenticated, dailyTopBuyContent]);

  const clickHandler = (e: React.MouseEvent) => {
    const target = e.target as HTMLAnchorElement;
    const event: string = target?.href?.includes('activities')
      ? 'click_insider_trader_actvities'
      : target?.href?.includes('profile')
      ? 'click_insider_trader_profile'
      : '';
    const howItWorks: boolean = target?.href?.includes('how-it-works');
    if (event) {
      trackEvent(event, {
        ScreenName: 'DailyTopInsiderBuys',
        path: window.location.href,
        TargetURL: target?.href
      });
    }

    if (howItWorks) {
      trackEvent('click_insider_trader_how_it_works', {
        ScreenName: 'DailyTopInsiderBuys',
        path: window.location.href,
        TargetURL: target?.href
      });
    }
  };

  return (
    <RootStyle title="Daily top insider buys | TradingTown Space">
      <Container>
        <ContentStyle>
          <BreadCrumb>
            <ul>
              <li>
                <Link to={'/'}>TradingTown</Link>
              </li>
              <li>
                {' '}
                {'>'} <Link to={'/daily-top-insider-buys'}>Past Daily Top Insider Buys</Link>
              </li>
              <li>
                {' '}
                {'>'} {date ? moment(date).format('MMM D, YYYY') : ''}
              </li>
            </ul>
          </BreadCrumb>
          <div
            className="content-ctn"
            onClick={clickHandler}
            dangerouslySetInnerHTML={{ __html: dailyTopBuyContent }}
          ></div>
        </ContentStyle>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'rgba(22,28,36, 0.5)' }}
          open={isLoadingDaily}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </RootStyle>
  );
}
