// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STOCK = '/stock';

const ROOTS_WATCHLIST = '/watchlist';
const ROOTS_INSIDER_TRADING = '/insidertrading';
const ROOTS_SUBSCRIPTION = '/subscription';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  terms: '/terms',
  policy: '/policy'
};

export const PATH_STOCK = {
  root: ROOTS_STOCK,
  detail: ROOTS_STOCK
};

export const PATH_WATCHLIST = {
  root: ROOTS_WATCHLIST
};

export const PATH_SUBSCRIPTION = {
  root: ROOTS_SUBSCRIPTION
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/id/edit`)
  },
  watch: {
    root: path(ROOTS_DASHBOARD, '/watch'),
    list: path(ROOTS_DASHBOARD, '/watch/list')
  },
  subscription: {
    root: path(ROOTS_DASHBOARD, '/subscription')
  }
};

export const PATH_INSIDER_TRADING = {
  root: ROOTS_INSIDER_TRADING,
  activities: path(ROOTS_INSIDER_TRADING, '/activities'),
  insiders: path(ROOTS_INSIDER_TRADING, '/traders'),
  algo_trading_showcase: path(ROOTS_INSIDER_TRADING, '/algo_trading_showcase')
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
