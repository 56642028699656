import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { RootState, useSelector } from '../../redux/store';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import { setShowRegistrationModal } from '../../redux/slices/user';
import { useDispatch } from '../../redux/store';
import { trackEvent } from '../../components/amplitute';

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}));

export default function CardsSection(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { subscription } = useSelector((state: RootState) => state.subscription);

  const cardClick = (date: string) => {
    trackEvent('click_top_insider_trades', {
      type: props.type,
      date: date,
      ScreenName: 'Top insider trades',
      path: window.location.href
    });
    const expiresAt = subscription?.expires_at
      ? moment.utc(subscription.expires_at).local().valueOf()
      : 0;
    const formattedDate = moment(date).format('YYYY-MM-DD');
    if (!isAuthenticated && props.type !== 'weekly') {
      dispatch(setShowRegistrationModal());
    } else if (subscription?.status !== 'active' && props.type !== 'weekly') {
      if (moment().toDate().getTime() > expiresAt) {
        //Show modal
        props.triggerModal(true);
      } else {
        navigate(`/daily-top-insider-buy/${formattedDate}`);
      }
    } else {
      if (props.type === 'daily') {
        navigate(`/daily-top-insider-buy/${formattedDate}`);
      } else if (props.type === 'weekly') {
        navigate(`/weekly-top-insider-buy/${formattedDate}`);
      }
    }
  };

  function getRandomImg(key: number) {
    const randomNm = (key % 5) + 1;
    return `/static/components/poster${randomNm}.png`;
  }

  return (
    <CardContainer>
      {props.topBuys?.map((item: string, key: number) => (
        <Card
          sx={{ width: 235, height: 200, borderRadius: '8px', cursor: 'pointer' }}
          key={key}
          onClick={() => cardClick(item)}
        >
          <CardMedia component="img" height="120" image={getRandomImg(key)} alt="Paella dish" />
          <CardContent sx={{ padding: '12px' }}>
            <Typography variant="body2" color="text.secondary">
              {props.type === 'daily' ? 'Daily' : 'Weekly'} Top Insider Buys for{' '}
              <p>{moment(item).format('YYYY-MM-DD')}</p>
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ padding: 0, justifyContent: 'right' }}>
            {/* <IconButton aria-label="share" sx={{ fontSize: '12px' }}>
                    <ShareIcon sx={{ fontSize: '12px' }} /> Share
                  </IconButton> */}
          </CardActions>
        </Card>
      ))}
    </CardContainer>
  );
}
