import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import { NavConfig } from '../../@types/nav-config';
// routes
import {
  PATH_AUTH,
  PATH_DOCS,
  PATH_PAGE,
  PATH_DASHBOARD,
  PATH_WATCHLIST
} from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig: NavConfig = {
  default: [
    {
      title: 'Blog',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: 'https://medium.com/@tradingtownofcmu',
      isAuthenticated: undefined
    },
    {
      title: 'Watchlist',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_WATCHLIST.root,
      isAuthenticated: undefined
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Profile',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_DASHBOARD.root,
      isAuthenticated: true
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {}
    }
  ],
  '/terms': [],
  '/policy': [],
  '/insidertrading': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      screenName: 'InsiderTrading',
      menuItemOnClick: () => {}
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      screenName: 'InsiderTrading',
      menuItemOnClick: () => {}
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      screenName: 'InsiderTrading',
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'InsiderTrading'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'InsiderTrading'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      screenName: 'InsiderTrading',
      menuItemOnClick: () => {}
    }
    // {
    //   title: 'Sign out',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/insidertrading',
    //   isAuthenticated: true,
    //   redirectURL: '/insidertrading',
    //   menuItemOnClick: () => {}
    // }
  ],
  '/daily-top-insider-buys': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/daily-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuys'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuys'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'DailyTopInsiderBuys'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'DailyTopInsiderBuys'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuys'
    }
  ],
  '/daily-top-insider-buy/:date': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/daily-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuyByDate'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuyByDate'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'DailyTopInsiderBuyByDate'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'DailyTopInsiderBuyByDate'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'DailyTopInsiderBuyByDate'
    }
  ],
  '/weekly-top-insider-buys': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/weekly-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'WeeklyTopInsiderBuys'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'WeeklyTopInsiderBuys'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    }
  ],
  '/top-buys-30-days': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/weekly-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'WeeklyTopInsiderBuys'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'WeeklyTopInsiderBuys'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuys'
    }
  ],
  '/weekly-top-insider-buy/:date': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/weekly-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'WeeklyTopInsiderBuyByDate'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'WeeklyTopInsiderBuyByDate'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    }
  ],
  '/how-it-works': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/weekly-top-insider-buys'
    },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'WeeklyTopInsiderBuyByDate'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'WeeklyTopInsiderBuyByDate'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'WeeklyTopInsiderBuyByDate'
    }
  ],
  '/pricing': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'Pricing'
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'Pricing'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'Pricing'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'Pricing'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'Pricing'
        }
      ],
      menuItemOnClick: () => {}
    }
    // {
    //   title: 'Sign out',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/insidertrading',
    //   isAuthenticated: true,
    //   redirectURL: '/insidertrading',
    //   menuItemOnClick: () => {}
    // }
  ],
  '/myaccount': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'MyAccount'
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'My Account',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/myaccount',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {},
      screenName: 'MyAccount'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'MyAccount'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'MyAccount'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'MyAccount'
        }
      ],
      menuItemOnClick: () => {}
    }
    // {
    //   title: 'Sign out',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/insidertrading',
    //   isAuthenticated: true,
    //   redirectURL: '/insidertrading',
    //   menuItemOnClick: () => {}
    // }
  ],
  '/insidertrading/activities': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/activities'
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'InsiderTradingActivities'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'InsiderTradingActivities'
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'Premiums',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'InsiderTradingActivities'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'InsiderTradingActivities'
        }
      ],
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/activities',
      isAuthenticated: true,
      redirectURL: '/insidertrading/activities',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/traders': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/traders'
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'InsiderTraders'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'InsiderTraders'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'InsiderTraders'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'InsiderTraders'
        }
      ],
      menuItemOnClick: () => {}
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/traders',
      isAuthenticated: true,
      redirectURL: '/insidertrading/traders',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/profile/:insiderId': [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: undefined
    },
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/profile/:insiderId'
    },
    {
      title: 'Pricing',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/pricing',
      isAuthenticated: undefined,
      menuItemOnClick: () => {},
      screenName: 'InsiderTradersProfile'
    },
    {
      title: 'Weekly Tops',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/weekly-top-insider-buys',
      isAuthenticated: undefined,
      signInToolTip: false,
      menuItemOnClick: () => {},
      screenName: 'InsiderTradersProfile'
    },
    {
      title: 'Premium',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '',
      isAuthenticated: undefined,
      childItems: [
        {
          title: 'Daily Top Insider Buys',
          path: '/daily-top-insider-buys',
          screenName: 'InsiderTradersProfile'
        },
        {
          title: 'Top Buys in the Past 30 Days',
          path: '/top-buys-30-days',
          screenName: 'InsiderTradersProfile'
        }
      ],
      menuItemOnClick: () => {}
    },
    // {
    //   title: 'Email Alerts',
    //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    //   path: '/email-preference',
    //   isAuthenticated: true,
    //   menuItemOnClick: () => {}
    // },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/profile/:insiderId',
      isAuthenticated: true,
      redirectURL: '/insidertrading/profile/:insiderId',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/promotion': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/promotion',
      isAuthenticated: true,
      redirectURL: '/insidertrading/promotion',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/algo_trading_showcase': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/promotion',
      isAuthenticated: true,
      redirectURL: '/insidertrading/promotion',
      menuItemOnClick: () => {}
    }
  ]
};

export default menuConfig;
