import { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
// // material
import {
  Stack,
  InputLabel,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Alert,
  Modal,
  FormControl,
  InputBase,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import useAuth from '../../../hooks/useAuth';
import { Icon } from '@iconify/react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import closeFill from '@iconify/icons-eva/close-fill';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { MIconButton } from '../../@material-extend';
import { Link, useLocation } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { FB_APP_ID } from '../../../config/fb-app';
import { RootState, useSelector, dispatch } from '../../../redux/store';
import { setHideLoginRegModal } from '../../../redux/slices/user';
import {
  ButtonTracking,
  stockBtnTabEventTracking,
  event_types,
  signInTracking
} from 'components/amplitute';

const ModalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 768,
  minHeight: 351,
  borderRadius: '10px',
  background: '#FFF',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute' as 'absolute',
  '&.wd70': {
    width: '65%',
    height: '65%'
  },
  [theme.breakpoints.down('md')]: {
    width: '85%',
    '&.wd70': {
      width: '90%'
    }
  }
}));

const LeftSection = styled('div')(({ theme }) => ({
  width: '50%',
  borderTopLeftRadius: '10px',
  '&.wd70': {
    paddingTop: '10%'
  },
  background:
    'linear-gradient(180deg, #1565D8 0%, #1565D8 41.01%, rgba(217, 217, 217, 0.00) 100%), linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%), url("/static/auth/image_4.png") no-repeat bottom',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));
const Leftsectionchild = styled('div')(({ theme }) => ({
  width: '90%',
  margin: '32px auto 0 29px',
  color: '#FFF',
  fontStyle: 'normal',
  lineHeight: 'normal',
  h2: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 15
  },
  h4: {
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: 11
  }
}));

const RightSectionTop = styled('div')(({ theme }) => ({
  width: '50%',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  '&.wd70': {
    paddingTop: '10%'
  },
  background:
    'linear-gradient(180deg, #1565D8 0%, #1565D8 41.01%, rgba(217, 217, 217, 0.00) 100%), linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%)',
  [theme.breakpoints.down('sm')]: {
    display: 'table-cell',
    textAlign: '-webkit-center'
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));
const RightSectionTopChild = styled('div')(({ theme }) => ({
  width: '90%',
  marginTop: '30px',
  color: '#FFF',
  fontStyle: 'normal',
  lineHeight: 'normal',
  paddingBottom: '50px',
  h2: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 15
  },
  h4: {
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: 11
  },
  p: {
    paddingBottom: 11
  }
}));

const RightSection = styled('div')(({ theme }) => ({
  width: '50%',
  paddingBottom: '20px',
  paddingTop: '15px',
  '.subscription-cont': {
    textAlign: 'center',
    '@media(max-width: 400px)': {
      textAlign: 'justify',
      '.MuiFormControlLabel-label': {
        fontSize: '12px'
      }
    }
  },
  '&.wd70': {
    paddingTop: '10%',
    '@media(max-width: 600px)': {
      paddingTop: 0
    }
  },
  '.back-btn': {
    fontSize: '8px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    span: {
      cursor: 'pointer'
    },
    svg: {
      width: '10px',
      marginRight: '4px',
      cursor: 'pointer'
    }
  },
  '.right-container': {
    paddingLeft: '36px',
    paddingRight: '28px'
  },
  '.btn-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '.cross-btn': {
    textAlign: 'right',
    height: '24px',
    paddingRight: '10px',
    svg: {
      cursor: 'pointer',
      width: '14px',
      color: '#9C9A9A'
    }
  },
  '.cros-2': {
    display: 'block'
  },
  '.cros-m': {
    paddingRight: '0px',
    svg: {
      color: 'white'
    }
  },
  '.login-btn': {
    width: '270px',
    height: '40px',
    borderRadius: '5px',
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '26px center',
    color: '#3c4043',
    fontSize: '14px',
    marginBottom: '10px',
    fontWeight: 400,
    cursor: 'pointer',
    backgroundSize: '20px',
    backgroundPositionX: '48px',
    paddingLeft: '7px'
  },
  '.fb-login-btn': {
    backgroundImage: 'url(/static/auth/fb_icon.png)',
    backgroundPositionX: '36px'
  },
  '.google-login-btn': {
    backgroundImage: 'url(/static/auth/google_icon.png)'
  },
  '.email-login-btn': {
    marginTop: '10px',
    backgroundImage: 'url(/static/auth/email_icon.png)'
  },
  '.acct-section': {
    marginBottom: '31px',
    a: {
      cursor: 'pointer'
    }
  },
  '.email-pref-msg': {
    color: '#9C9A9A',
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '14px'
  },
  h3: {
    color: '#000',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
    marginBottom: '4px'
  },
  h4: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    display: 'inline'
  },
  a: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
    textDecorationLine: 'underline'
  },
  '.login-with-email': {
    '.MuiInputLabel-root': {
      fontSize: '14px',
      color: '#000'
    },
    '.err-msg': {
      color: '#FF4842',
      lineHeight: 1.5,
      fontSize: '0.75rem',
      marginTop: '5px'
    },
    '.forgot-pass': {
      fontSize: '11px',
      textAlign: 'right',
      color: '#000',
      marginBottom: '35px',
      paddingTop: '8px'
    },
    '.MuiFormControl-root': {
      marginTop: '10px'
    },
    'button[type=submit]': {
      fontWeight: 'normal'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingTop: 0,
    textAlign: 'center',
    '.cross-btn': {
      marginRight: '10px'
    },
    '.cros-2': {
      display: 'none'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #E5E5E5',
    fontSize: 16,
    width: '100%',
    padding: '2px 5px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: '#FF4842'
  },
  '.MuiInputAdornment-root': {
    position: 'absolute',
    right: '15px'
  }
}));

const modalText: { [key: string]: any } = {
  registration: {
    heading: 'Create a new account',
    subHeading: 'Already have an account?'
  },
  login: {
    heading: 'Sign in to your account',
    subHeading: 'Don’t have an account?'
  },
  loginWithMail: {
    heading: 'Continue with your Email',
    subHeading: ''
  },
  registrationWithMail: {
    heading: 'Create a new account',
    subHeading: 'Already have an account?'
  }
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required')
});

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  name?: string;
  afterSubmit?: string;
};

export default function LoginRegisterFormModal(props: any) {
  const { showLoginRegistrationModal, showCloseButton, showSubHeading } = useSelector(
    (state: RootState) => state.user
  );
  const location = useLocation();
  const { fbLogin, googleLogin, login, register } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [subscribeToAlerts, setSubscribeToAlerts] = useState(true);
  const [showLoginRegModal, setShowLoginRegModal] = useState(showLoginRegistrationModal);
  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      name: '',
      remember: true
    },
    validationSchema:
      showLoginRegModal === 'registrationWithMail' ? RegistrationSchema : LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (showLoginRegModal === 'registrationWithMail') {
          await register(
            values.email,
            values.password,
            values.name || '',
            false,
            subscribeToAlerts
          );
          enqueueSnackbar('Registration success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          enqueueSnackbar('Please verify your email', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        } else {
          await login(values.email, values.password);
          //setUserId(values.email);
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }

        dispatch(setHideLoginRegModal());
      } catch (error: any) {
        console.error(error);
        resetForm();
        setErrors({ afterSubmit: error?.message });
      }
    }
  });

  const { errors, touched, isSubmitting, dirty, handleSubmit, getFieldProps, isValid } = formik;

  const responseFacebook = async (response: any) => {
    if (response?.id) {
      try {
        await fbLogin({
          ...response,
          subscribeToAlerts:
            showLoginRegModal === 'login' || showLoginRegModal === 'loginWithMail'
              ? undefined
              : subscribeToAlerts
        });
        dispatch(setHideLoginRegModal());
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (e) {
        enqueueSnackbar('Login failed', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } else {
      enqueueSnackbar('Login failed', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const responseGoogle = async (response: any) => {
    if (!response?.error) {
      try {
        await googleLogin({
          ...response,
          subscribeToAlerts:
            showLoginRegModal === 'login' || showLoginRegModal === 'loginWithMail'
              ? undefined
              : subscribeToAlerts
        });
        stockBtnTabEventTracking(
          'LoginPage',
          '',
          event_types.btnClick,
          `Login with Google`,
          'Authentication',
          window.location.href
        );
        dispatch(setHideLoginRegModal());
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (e) {
        enqueueSnackbar('Login failed', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } else {
      enqueueSnackbar('Login failed', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const toggleModal = () => {
    if (showLoginRegModal === 'registration' || showLoginRegModal === 'registrationWithMail') {
      setShowLoginRegModal('login');
    } else if (showLoginRegModal === 'login' || showLoginRegModal === 'loginWithMail') {
      setShowLoginRegModal('registration');
    }
  };

  const hideModal = () => {
    stockBtnTabEventTracking(
      'RegistrationLoginModal',
      '',
      event_types.btnClick,
      `Close modal`,
      'Authentication',
      window.location.href
    );
    dispatch(setHideLoginRegModal());
  };

  const loginWithEmail = () => {
    formik.resetForm();
    if (showLoginRegModal === 'registration') {
      setShowLoginRegModal('registrationWithMail');
    } else {
      setShowLoginRegModal('loginWithMail');
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
    stockBtnTabEventTracking(
      'LoginPage',
      '',
      event_types.btnClick,
      `Show Password`,
      'Authentication',
      window.location.href
    );
  };

  const submitClick = () => {
    if (showLoginRegModal === 'loginWithMail') {
      stockBtnTabEventTracking(
        'LoginPage',
        '',
        event_types.btnClick,
        `Login Button`,
        'Authentication',
        window.location.href
      );
    } else {
      stockBtnTabEventTracking(
        'Registration',
        '',
        event_types.btnClick,
        `Register Button`,
        'Authentication',
        window.location.href
      );
    }
  };

  const onPreferenceChange = (e: { [key: string]: any }) => {
    setSubscribeToAlerts(e.target.checked);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableScrollLock={showCloseButton ? false : true}
      sx={{ '& > .MuiBackdrop-root': { background: 'rgba(0,0,0,0.76)' } }}
    >
      <ModalContainer className={showCloseButton ? '' : 'wd70'}>
        <LeftSection className={showCloseButton ? '' : 'wd70'}>
          <Leftsectionchild>
            <h2>Get Data-Driven Insider Insights NOW</h2>
            <h4>AI-Powered Precise and Comprehensive Analysis</h4>
            <h4>Weekly Top Insider Trades Report</h4>
            <h4>Sign Up For Free</h4>
          </Leftsectionchild>
        </LeftSection>
        <RightSection className={showCloseButton ? '' : 'wd70'}>
          <div className="cross-btn cros-2">
            {showCloseButton ? <CloseIcon onClick={hideModal} /> : null}
          </div>
          <RightSectionTop className={showCloseButton ? '' : 'wd70'}>
            <div className="cross-btn cros-m">
              {showCloseButton ? <CloseIcon onClick={hideModal} /> : null}
            </div>
            <RightSectionTopChild>
              <h2>Stay Updated with Insider Trading Alerts</h2>
              <p>AI-Powered Precise and Comprehensive Analysis</p>
              <p>Weekly Top Insider Trades Report</p>
              <p>Sign Up For Free</p>
            </RightSectionTopChild>
          </RightSectionTop>
          <div className="right-container">
            {showLoginRegModal === 'loginWithMail' ? (
              <div className="back-btn" onClick={() => setShowLoginRegModal('registration')}>
                <WestIcon />
                <span>Back</span>
              </div>
            ) : null}
            {showSubHeading ? (
              <h3>{showLoginRegModal && modalText[showLoginRegModal]?.heading}</h3>
            ) : (
              <h3 style={{ marginBottom: '40px' }}>
                {showLoginRegModal && modalText[showLoginRegModal]?.heading}
              </h3>
            )}

            {showSubHeading ? ( // "Don't have an account?" / "Already have an account?"
              <div className="acct-section">
                <h4>{showLoginRegModal && modalText[showLoginRegModal]?.subHeading} </h4>
                {showLoginRegModal !== 'loginWithMail' ? (
                  <a onClick={toggleModal}>
                    {showLoginRegModal === 'registration' ||
                    showLoginRegModal === 'registrationWithMail'
                      ? 'Sign in'
                      : 'Join NOW'}
                  </a>
                ) : null}
              </div>
            ) : (
              ''
            )}

            {showLoginRegModal !== 'loginWithMail' &&
            showLoginRegModal !== 'registrationWithMail' ? (
              <div className="btn-container">
                {FB_APP_ID ? (
                  <FacebookLogin
                    appId={FB_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button onClick={renderProps.onClick} className="login-btn fb-login-btn">
                        Continue with Facebook
                      </button>
                    )}
                  />
                ) : null}
                <GoogleLogin
                  text="continue_with"
                  width="270px"
                  logo_alignment="center"
                  prompt_parent_id="glogin"
                  onSuccess={responseGoogle}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
                <button onClick={loginWithEmail} className="login-btn email-login-btn">
                  Continue with Email
                </button>
              </div>
            ) : (
              <div className="login-with-email">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                      {showLoginRegModal === 'registrationWithMail' ? (
                        <FormControl variant="standard">
                          <InputLabel shrink htmlFor="bootstrap-input">
                            Name
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            type="text"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                          />
                          {touched.name && errors.name ? (
                            <span className="err-msg">{errors.name}</span>
                          ) : null}
                        </FormControl>
                      ) : null}

                      <FormControl variant="standard">
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Email
                        </InputLabel>
                        <BootstrapInput
                          fullWidth
                          autoComplete="username"
                          type="email"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email ? (
                          <span className="err-msg">{errors.email}</span>
                        ) : null}
                      </FormControl>

                      <FormControl variant="standard">
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Password
                        </InputLabel>
                        <BootstrapInput
                          fullWidth
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                {showPassword ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {touched.password && errors.password ? (
                          <span className="err-msg">{errors.password}</span>
                        ) : null}
                      </FormControl>
                    </Stack>
                    <div className="forgot-pass">
                      <Link
                        to={'/auth/forgot-password'}
                        onClick={() => {
                          stockBtnTabEventTracking(
                            'LoginPage',
                            '',
                            event_types.btnClick,
                            `Forgot password? Login`,
                            'Authentication',
                            window.location.href
                          );
                        }}
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <Stack>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={submitClick}
                        loadingPosition="start"
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        Continue
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </div>
            )}
            <div className="subscription-cont">
              {false &&
              (showLoginRegModal === 'registration' ||
                showLoginRegModal === 'registrationWithMail') ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onPreferenceChange}
                      value="subscribe"
                      checked={subscribeToAlerts}
                    />
                  }
                  label="Also subscribe to insider trading alerts (Free Trial)"
                />
              ) : null}
            </div>
            {location.pathname.includes('email-preference') ? (
              <div className="email-pref-msg">
                You need to be signed in to manage your email preferences.
              </div>
            ) : null}
          </div>
        </RightSection>
      </ModalContainer>
    </Modal>
  );
}
