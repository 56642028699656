import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type InsiderTradingState = {
  isLoading: boolean;
  error: boolean;
  volume: any;
  ratio: any;
  insiderTradingActivities: any;
  trendingCongressList: any;
};

const initialState: InsiderTradingState = {
  isLoading: false,
  error: false,
  volume: null,
  ratio: null,
  insiderTradingActivities: null,
  trendingCongressList: null
};

const slice = createSlice({
  name: 'stockinsidertrading',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    resetTradingActivities(state) {
      state.isLoading = false;
      state.insiderTradingActivities = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSuccessVolume(state, action) {
      state.isLoading = false;
      state.volume = action.payload;
    },

    getSuccessRatio(state, action) {
      state.isLoading = false;
      state.ratio = action.payload;
    },
    saveInsiderTradingActivities(state, action) {
      state.isLoading = false;
      state.insiderTradingActivities = action.payload;
    },
    getTrendingCards(state, action) {
      state.isLoading = false;
      state.trendingCongressList = action.payload;
    },
    getInsiderTradingActivities(state, action) {
      state.isLoading = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// Actions
export function resetTradingActivities() {
  return async () => {
    dispatch(slice.actions.resetTradingActivities());
  };
}
// ----------------------------------------------------------------------

export function getVolume(ticker: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/insidertrading/volume/${ticker}`);
      dispatch(slice.actions.getSuccessVolume(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRatio(ticker: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/insidertrading/ratio/${ticker}`);
      dispatch(slice.actions.getSuccessRatio(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInsiderTradingActivity(filters: { [index: string]: string | null | number }) {
  const queryParams = Object.keys(filters).map((item) => `${item}=${filters[item]}`);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/insider-trading/activities?${queryParams.join('&')}`
      );
      dispatch(slice.actions.saveInsiderTradingActivities(response?.data));
    } catch (error) {
      dispatch(slice.actions.saveInsiderTradingActivities({ error: true }));
    }
  };
}

export function getTrendingCongress() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/cards/trending`);
      dispatch(slice.actions.getTrendingCards(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
