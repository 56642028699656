import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { PATH_AUTH } from 'routes/paths';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  ButtonTracking,
  TabTracking,
  stockBtnTabEventTracking,
  event_types,
  trackEvent
} from 'components/amplitute';
const CardContainer = styled(Card)(({ theme }) => ({
  width: '276px',
  margin: 'auto',
  overflow: 'visible',
  borderRadius: '10px',
  paddingBottom: '20px',
  h5: {
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '15px'
  },
  p: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#918484'
  },
  a: {
    paddingTop: '10px',
    textAlign: 'center',
    display: 'block'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const CloseContainer = styled('div')(({ theme }) => ({
  textAlign: 'right',
  position: 'absolute',
  right: 0,
  svg: {
    cursor: 'pointer',
    right: 0,
    color: '#fff'
  }
}));

const NewFeatureModal = ({ showModal, onClose }: { showModal: boolean; onClose: any }) => {
  const [open, setShowModal] = useState(true);
  const Location = useLocation();

  useEffect(() => {
    setShowModal(showModal);
  }, [showModal]);

  const handleClose = () => {
    sessionStorage.setItem('tourCompleted', 'true');
    trackEvent('click_mobile_new_feature_modal_close', {
      ScreenName: 'Activities',
      buttonName: 'Modal Close',
      path: window.location.href
    });
    setShowModal(false);
    onClose();
  };

  const onClickRoutes = (btn: string) => {
    sessionStorage.setItem('tourCompleted', 'true');
    trackEvent('click_mobile_new_feature_modal', {
      ScreenName: 'Activities',
      buttonName: btn,
      path: window.location.href
    });
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={true}
      onClose={handleClose}
      BackdropProps={{ style: { background: 'rgba(0,0,0, 0.76)' } }}
      sx={{ width: 276, top: '10%', margin: 'auto' }}
    >
      <CardContainer>
        <CloseContainer>
          <CloseIcon onClick={handleClose} />
        </CloseContainer>
        <img src={'/static/new-feature.png'} />
        <div>
          <h5>New Features for Your Trading</h5>
          <p>TradingTown now offers the following summaries for you:</p>
          <RouterLink
            to="/weekly-top-insider-buys"
            onClick={() => onClickRoutes('Weekly Top Trades')}
          >
            Weekly Top Trades
          </RouterLink>
          <RouterLink
            to="/daily-top-insider-buys"
            onClick={() => onClickRoutes('Daily Top Insider Buys')}
          >
            Daily Top Insider Buys
          </RouterLink>
          <RouterLink
            to="/top-buys-30-days"
            onClick={() => onClickRoutes('Last 30 Day Top Insider Buys')}
          >
            Last 30 Day Top Insider Buys
          </RouterLink>
        </div>
      </CardContainer>
    </Modal>
  );
};

export default NewFeatureModal;
