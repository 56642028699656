import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import Terms from 'pages/Terms';
import Policy from 'pages/Policy';
import Pricing from 'pages/pricing';
import AlgoTradingShowcase from 'pages/insiderTrading/AlgoTradingShowcase';
import MyAccount from 'pages/myaccount';
import DailyTopInsiderBuys from 'pages/top-insider-buys/Daily';
import WeeklyTopInsiderBuys from 'pages/top-insider-buys/Weekly';
import DailyTopInsiderBuy from 'pages/top-insider-buys/DailyTopBuy';
import WeeklyTopInsiderBuy from 'pages/top-insider-buys/WeeklyTopBuy';
import HowItWorks from 'pages/HowItWorks';
import TopBuys from 'pages/top-insider-buys/TopBuysLastThirtyDays';
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'verify/:userId/:token', element: <Verify /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/analytics" replace /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        {
          path: 'watch',
          element: <WatchList />
        }
      ]
    },
    {
      path: 'stock',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: 'all/:stockSymbol', element: <StockAllDetailsPage /> },
        { path: ':stockSymbol', element: <StockPage /> }
        // { element: <Navigate to="/" replace /> }
      ]
    },
    {
      path: 'watchlist',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [{ element: <WatchListNew /> }]
    },
    {
      path: 'daily-top-insider-buys',
      element: <MainLayout />,
      children: [{ element: <DailyTopInsiderBuys /> }]
    },
    {
      path: 'weekly-top-insider-buys',
      element: <MainLayout />,
      children: [{ element: <WeeklyTopInsiderBuys /> }]
    },
    {
      path: 'daily-top-insider-buy/:date',
      element: <MainLayout />,
      children: [{ element: <DailyTopInsiderBuy /> }]
    },
    {
      path: 'weekly-top-insider-buy/:date',
      element: <MainLayout />,
      children: [{ element: <WeeklyTopInsiderBuy /> }]
    },
    {
      path: '/insidertrading',
      element: <MainLayout />,
      children: [
        { element: <InsiderTrading /> },
        { path: 'activities', element: <InsiderTradingActivities /> },
        { path: 'traders', element: <AllInsider /> },
        { path: 'profile/:insiderId', element: <InsiderProfile /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'algo_trading_showcase', element: <AlgoTradingShowcase /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <Navigate to="/insidertrading" /> }]
    },
    {
      path: '/404',
      element: <MainLayout />,
      children: [{ element: <Page404 /> }]
    },
    {
      path: '/terms',
      element: <MainLayout />,
      children: [{ element: <Terms /> }]
    },
    {
      path: '/policy',
      element: <MainLayout />,
      children: [{ element: <Policy /> }]
    },
    {
      path: '/how-it-works',
      element: <MainLayout />,
      children: [{ element: <HowItWorks /> }]
    },
    {
      path: '/top-buys-30-days',
      element: <MainLayout />,
      children: [{ element: <TopBuys /> }]
    },
    {
      path: '/pricing',
      element: <MainLayout />,
      children: [{ element: <Pricing /> }]
    },
    {
      path: '/myaccount',
      element: <MainLayout />,
      children: [{ element: <MyAccount /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const Verify = Loadable(lazy(() => import('../pages/authentication/Verify')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralAnalytics = Loadable(
  lazy(() => import('../pages/dashboard/Analytics/GeneralAnalytics'))
);
const WatchList = Loadable(lazy(() => import('../pages/dashboard/Watch/WatchList')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// Components

const StockPage = Loadable(lazy(() => import('../pages/stock/StockDetails')));
const StockAllDetailsPage = Loadable(lazy(() => import('../pages/stock/StockAllUOADetails')));
const WatchListNew = Loadable(lazy(() => import('../pages/watchlist/WatchList')));
const InsiderTrading = Loadable(lazy(() => import('../pages/insiderTrading/InsiderTrading')));
const Promotion = Loadable(lazy(() => import('../pages/insiderTrading/Promotion')));
const InsiderTradingActivities = Loadable(
  lazy(() => import('../pages/insiderTrading/InsiderTradingActivities'))
);

const InsiderProfile = Loadable(lazy(() => import('../pages/insiderTrading/InsiderProfile')));
const AllInsider = Loadable(lazy(() => import('../pages/insiderTrading/AllInsider')));
