import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';

type TopInsiderBuyState = {
  isLoadingDaily: boolean;
  isLoadingWeekly: boolean;
  dailyError: boolean;
  weeklyError: boolean;
  dailyTopBuys: any[];
  weeklyTopBuys: any[];
  dailyTopBuyContent: string;
  isLoadingThiryDays: boolean;
  thirtyDays: string;
};

const initialState: TopInsiderBuyState = {
  isLoadingDaily: false,
  isLoadingWeekly: false,
  dailyError: false,
  weeklyError: false,
  dailyTopBuys: [],
  weeklyTopBuys: [],
  dailyTopBuyContent: '',
  isLoadingThiryDays: false,
  thirtyDays: ''
};

const slice = createSlice({
  name: 'topInsiderBuys',
  initialState,
  reducers: {
    // START LOADING
    startLoadingDaily(state) {
      state.isLoadingDaily = true;
    },
    startLoadingWeekly(state) {
      state.isLoadingWeekly = true;
    },

    // HAS ERROR
    hasDailyError(state, action) {
      state.isLoadingDaily = false;
      state.dailyError = action.payload;
    },

    hasThirtyDaysError(state, action) {
      state.isLoadingThiryDays = false;
      state.thirtyDays = '';
    },

    hasWeeklyError(state, action) {
      state.isLoadingWeekly = false;
      state.weeklyError = action.payload;
    },

    getDailyTopBuysContentSuccess(state, action) {
      state.isLoadingDaily = false;
      state.dailyTopBuyContent = action.payload;
    },

    getThirtyDaysTopBuysContentSuccess(state, action) {
      state.isLoadingThiryDays = false;
      state.thirtyDays = action.payload;
    },

    getDailyTopBuysDatesListSuccess(state, action) {
      state.isLoadingDaily = false;
      state.dailyTopBuys = action.payload;
    },

    getWeeklyTopBuysListSuccess(state, action) {
      state.isLoadingWeekly = false;
      state.weeklyTopBuys = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDailyTopBuysList(date: string) {
  return async () => {
    dispatch(slice.actions.startLoadingDaily());
    try {
      const response = await axios.get(
        `/api/v1/insider-trading/daily-top-trades?targetdate=${date}`
      );
      // const response = { data: { results: [] } };
      dispatch(
        slice.actions.getDailyTopBuysContentSuccess(response.data?.transactionTable?.result)
      );
    } catch (error) {
      dispatch(slice.actions.hasDailyError(error));
    }
  };
}

export function getThirtyDaysTopBuysList() {
  return async () => {
    dispatch(slice.actions.startLoadingDaily());
    try {
      const response = await axios.get(`/api/v1/insider-trading/top-trades-past-thirty-days`);
      // const response = { data: { results: [] } };
      dispatch(
        slice.actions.getThirtyDaysTopBuysContentSuccess(response.data?.transactionTable?.result)
      );
    } catch (error) {
      dispatch(slice.actions.hasThirtyDaysError(error));
    }
  };
}

export function getDailyTopBuysDatesList() {
  return async () => {
    dispatch(slice.actions.startLoadingDaily());
    try {
      const response = await axios.get('/api/v1/insider-trading/daily-top-trades-grid');
      // const response = { data: { results: [] } };
      dispatch(slice.actions.getDailyTopBuysDatesListSuccess(response.data?.dates_available));
    } catch (error) {
      dispatch(slice.actions.hasDailyError(error));
    }
  };
}

export function getWeeklyTopBuysList() {
  return async () => {
    dispatch(slice.actions.startLoadingWeekly());
    try {
      const response = await axios.get('/api/v1/insider-trading/weekly-top-trades');
      // const response = { data: { results: [] } };
      dispatch(slice.actions.getWeeklyTopBuysListSuccess(response.data?.reports));
    } catch (error) {
      dispatch(slice.actions.hasWeeklyError(error));
    }
  };
}
